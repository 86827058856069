//Lista das categorias de despesa para obter a descrição com base no código

const expenseCategories = {
    categoria: {},
    natureza: {
        1: 'PESSOAL E ENCARGOS SOCIAIS',
        2: 'JUROS E ENCARGOS DA DÍVIDA',
        3: 'OUTRAS DESPESAS CORRENTES',
        4: 'INVESTIMENTOS',
        5: 'INVERSÕES FINANCEIRAS',
        6: 'AMORTIZAÇÃO DA DÍVIDA'
    },
    modalidade: {
        20: 'Transferências à União',
        30: 'Transferências aos Estados e ao DF',
        40: 'Transferências aos Municípios',
        41: 'Transferências aos Municípios',
        42: 'Transferências aos Municípios',
        43: 'Transferências aos Municípios',
        44: 'Transferências aos Municípios',
        45: 'Transferências aos Municípios',
        46: 'Transferências aos Municípios',
        50: 'Transferências a instituições privadas sem fins lucrativos',
        60: 'Transferências a instituições privadas com fins lucrativos',
        67: 'Execução de contrato de Parcerias Público-Privada (PPP)',
        70: 'Transferências a instituições multigovernamentais',
        71: 'Transferências a instituições multigovernamentais',
        72: 'Transferências a instituições multigovernamentais',
        73: 'Transferências a instituições multigovernamentais',
        74: 'Transferências a instituições multigovernamentais',
        75: 'Transferências a instituições multigovernamentais',
        76: 'Transferências a instituições multigovernamentais',
        80: 'Transferências ao exterior',
        90: 'Aplicações Diretas',
        91: 'Aplicações Diretas',
        92: 'Aplicações Diretas',
        93: 'Aplicações Diretas',
        94: 'Aplicações Diretas',
        95: 'Aplicações Diretas',
        96: 'Aplicações Diretas',
        99: 'A Definir'
    },
    elemento: {
        1: 'Aposentadorias do RPPS, Reserva Remunerada e Reformas dos Militares',
        3: 'Pensões do RPPS e do militar',
        4: 'Contratação por Tempo Determinado',
        5: 'Outros Benefícios Previdenciários do servidor ou do militar',
        6: 'Benefício Mensal ao Deficiente e ao Idoso',
        7: 'Contribuição a Entidades Fechadas de Previdência',
        8: 'Outros Benefícios Assistenciais do servidor e do militar',
        10: 'Seguro Desemprego e Abono Salarial',
        11: 'Vencimentos e Vantagens Fixas – Pessoal Civil',
        12: 'Vencimentos e Vantagens Fixas – Pessoal Militar',
        13: 'Obrigações Patronais',
        14: 'Diárias – Civil',
        15: 'Diárias – Militar',
        16: 'Outras Despesas Variáveis – Pessoal Civil',
        17: 'Outras Despesas Variáveis – Pessoal Militar',
        18: 'Auxílio Financeiro a Estudantes',
        19: 'Auxílio-Fardamento',
        20: 'Auxílio Financeiro a Pesquisadores',
        21: 'Juros sobre a Dívida por Contrato',
        22: 'Outros Encargos sobre a Dívida por Contrato',
        23: 'Juros, Deságios e Descontos da Dívida Mobiliária',
        24: 'Outros Encargos sobre a Dívida Mobiliária',
        25: 'Encargos sobre Operações de Crédito por Antecipação da Receita',
        26: 'Obrigações decorrentes de Política Monetária',
        27: 'Encargos pela Honra de Avais, Garantias, Seguros e Similares',
        28: 'Remuneração de Cotas de Fundos Autárquicos',
        29: 'Distribuição de Resultado de Empresas Estatais Dependentes',
        30: 'Material de Consumo',
        31: 'Premiações Culturais, Artísticas, Científicas, Desportivas e Outras',
        32: 'Material, Bem ou Serviço para Distribuição Gratuita',
        33: 'Passagens e Despesas com Locomoção',
        34: 'Outras Despesas de Pessoal decorrentes de Contratos de Terceirização',
        35: 'Serviços de Consultoria',
        36: 'Outros Serviços de Terceiros – Pessoa Física',
        37: 'Locação de Mão-de-Obra',
        38: 'Arrendamento Mercantil',
        39: 'Outros Serviços de Terceiros – Pessoa Jurídica',
        41: 'Contribuições',
        42: 'Auxílios',
        43: 'Subvenções Sociais',
        45: 'Subvenções Econômicas',
        46: 'Auxílio-Alimentação',
        47: 'Obrigações Tributárias e Contributivas',
        48: 'Outros Auxílios Financeiros a Pessoas Físicas',
        49: 'Auxílio-Transporte',
        51: 'Obras e Instalações',
        52: 'Equipamentos e Material Permanente',
        53: 'Aposentadorias do RGPS – Área Rural',
        54: 'Aposentadorias do RGPS – Área Urbana',
        55: 'Pensões do RGPS – Área Rural',
        56: 'Pensões do RGPS – Área Urbana',
        57: 'Outros Benefícios do RGPS – Área Rural',
        58: 'Outros Benefícios do RGPS – Área Urbana',
        59: 'Pensões Especiais',
        61: 'Aquisição de Imóveis',
        62: 'Aquisição de Produtos para Revenda',
        63: 'Aquisição de Títulos de Crédito',
        64: 'Aquisição de Títulos Representativos de Capital já Integralizado',
        65: 'Constituição ou Aumento de Capital de Empresas',
        66: 'Concessão de Empréstimos e Financiamentos',
        67: 'Depósitos Compulsórios',
        70: 'Rateio pela Participação em Consórcio Público',
        71: 'Principal da Dívida Contratual Resgatado',
        72: 'Principal da Dívida Mobiliária Resgatado',
        73: 'Correção Monetária ou Cambial da Dívida Contratual Resgatada',
        74: 'Correção Monetária ou Cambial da Dívida Mobiliária Resgatada',
        75: 'Correção Monetária da Dívida de Operações de Crédito por Antecipação da Receita',
        76: 'Principal Corrigido da Dívida Mobiliária Refinanciado',
        77: 'Principal Corrigido da Dívida Contratual Refinanciado',
        81: 'Distribuição Constitucional ou Legal de Receitas',
        82: 'Aporte de Recursos pelo Parceiro Público em Favor do Parceiro Privado Decorrente de Contrato de Parceria Público-Privada – PPP',
        83: 'Despesas Decorrentes de Contrato de Parceria Público-Privada – PPP, exceto Subvenções Econômicas, Aporte e Fundo Garantidor',
        84: 'Despesas Decorrentes da Participação em Fundos, Organismos, ou Entidades Assemelhadas, Nacionais e Internacionais',
        91: 'Sentenças Judiciais',
        92: 'Despesas de Exercícios Anteriores',
        93: 'Indenizações e Restituições',
        94: 'Indenizações e Restituições Trabalhistas',
        95: 'Indenização pela Execução de Trabalhos de Campo',
        96: 'Ressarcimento de Despesas de Pessoal Requisitado',
        97: 'Aporte para Cobertura do Déficit Atuarial do RPPS',
        98: 'Compensações ao RGPS',
        99: 'A Classificar'
    },
    subelemento: {},

};

export default expenseCategories;